const counters = [
  {
    label: 'Cities',
    count: '400+',
  },
  {
    label: 'Countries',
    count: '30+',
  },
  {
    label: 'Hotels',
    count: '1,900+',
  },
];

export default counters;
